<template>
	<div style="width: 100%;" v-if="$store.state.user.id > 0">
		<NavBar></NavBar>

		<div class="internal-container">

			<LeftAccountList></LeftAccountList>

			<div class="dashboard-container">
				<div class="subnav-holder">
					<span class="subtab" @click="tabview='home'" :class="{subtabactive : tabview === 'home'}">
						<span>Home</span>
					</span>
					<span class="subtab" @click="tabview='accounts'" :class="{subtabactive : tabview === 'accounts'}">
						<span>Accounts</span>
					</span>
				</div>

				<div v-if="tabview === 'home'" class="account-tile-hold">

					<ChartHolderDash
						:charttitle="'Stock Prices'" :height="280"
						:addclasses="'chartholderdash'">
							<div class="list-table">
								<div class="list-table-item">
									<span class="bold list-table-item-cell" style="width: 120px;">Ticker</span>
									<span class="bold list-table-item-cell rt" style="width: 100px;">Price</span>
									<span class="bold list-table-item-cell rt" style="width: 100px;">Prev</span>
									<span class="bold list-table-item-cell rt" style="width: 100px;">Change</span>
								</div>
								<div v-for="sec in $store.state.securities" :key="sec.ticker">
									<div class="list-table-item" v-if="sec.latestprice" :class="{poschg : sec.latestprice >= sec.prevcloseprice, negchg : sec.latestprice < sec.prevcloseprice}">
										<span class="bold list-table-item-cell" style="width: 120px;">{{sec.ticker}}</span>
										<span class="list-table-item-cell rt" style="width: 100px;">{{numdisplay(sec.latestprice)}}</span>
										<span class="list-table-item-cell rt" style="width: 100px;">{{numdisplay(sec.prevcloseprice)}}</span>
										<span class="list-table-item-cell rt" style="width: 100px;">
											<span>
												<span v-if="sec.latestprice >= sec.prevcloseprice">+</span>
												{{sec.changePct}}%
											</span>
										</span>
									</div>
								</div>
							</div>
					</ChartHolderDash>

					<ChartHolderDash :charttitle="'All Ordinaries Past 30 days'" :height="280" :addclasses="'chartholderdash'">
						<div v-if="lookupdone" class="chart-container" style="width: 100%;">
							<apexchart width="99%" height="100%" type="area" :options="xaooptions" :series="xaoseries"></apexchart>
						</div>
						<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
					</ChartHolderDash>

					<ChartHolderDash
							:charttitle="'Net Worth'" :height="280"
							:addclasses="'chartholderdash'">
						<div class="list-table" v-if="lookupdone">
							<div class="list-table-item">
								<span class="bold list-table-item-cell" style="width: 120px;"></span>
								<span class="bold list-table-item-cell rt" style="width: 100px;">Today</span>
								<span class="bold list-table-item-cell rt" style="width: 100px;">30 days ago</span>
								<span class="bold list-table-item-cell rt" style="width: 100px;">Change</span>
							</div>
							<div>
								<div v-for="item in networthitems" :key="item.nm">
									<div class="list-table-item" v-if="dashboarddata[item.nm]">
										<span class="bold list-table-item-cell" style="width: 120px;">{{item.title}}</span>
										<span class="list-table-item-cell rt" style="width: 100px;">${{numdisplayround(dashboarddata[item.nm].today)}}</span>
										<span class="list-table-item-cell rt" style="width: 100px;">${{numdisplayround(dashboarddata[item.nm].prev30d)}}</span>
										<span class="list-table-item-cell rt" style="width: 100px;">
											<span>
												<span v-if="dashboarddata[item.nm].today >= dashboarddata[item.nm].prev30d">+</span>
												{{pctdiff(dashboarddata[item.nm].prev30d, dashboarddata[item.nm].today, 1)}}%
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
						<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
					</ChartHolderDash>

					<ChartHolderDash :charttitle="'Net Worth'" :height="280" :addclasses="'chartholderdash'">
						<div v-if="lookupdone" class="chart-container" style="width: 100%;">
							<apexchart width="99%" height="100%" type="area" :options="nwoptions" :series="nwseries"></apexchart>
						</div>
						<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
					</ChartHolderDash>

					<ChartHolderDash :charttitle="'Asset Allocation - Non Super'" :height="280" :addclasses="'chartholderdash'">
						<div v-if="lookupdone" class="chart-container" style="width: 100%; padding-left: 6%;">
							<apexchart width="88%" height="100%" type="donut" :options="aansoptions" :series="aansseries"></apexchart>
						</div>
						<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
					</ChartHolderDash>

					<ChartHolderDash :charttitle="'Asset Allocation - Super'" :height="280" :addclasses="'chartholderdash'">
						<div v-if="lookupdone" class="chart-container" style="width: 100%; padding-left: 6%;">
							<apexchart width="88%" height="100%" type="donut" :options="aasuperoptions" :series="aasuperseries"></apexchart>
						</div>
						<ChartLoaderInsert v-if="lookupdone === false"></ChartLoaderInsert>
					</ChartHolderDash>

				</div>

				<div v-if="tabview === 'accounts'" class="account-tile-hold">
					<AccountTile v-for="acc in accountsNotHidden" :accountobj="acc" :key="acc.id"></AccountTile>

				</div>
			</div>

		</div>

	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import LeftAccountList from "@/components/views/LeftAccountList";
import AccountTile from "@/components/AccountTile";
import ChartHolderDash from "@/components/ChartHolderDash";
import axios from "axios";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "Dashboard",
	components: {
		ChartLoaderInsert,
		ChartHolderDash,
		NavBar,
		LeftAccountList,
		AccountTile
	},
	props: {},
	data: function () {
		return {
			tabview: 'home',
			lookupdone: false,
			dashboarddata: [],
			networthitems: [
				{nm: 'networth', title: 'Total'},
				{nm: 'netexclhouse', title: 'Ex House'},
				{nm: 'netexclhousesuper', title: 'Ex House/Super'},
				{nm: 'networthexclwc', title: 'Total ex WC'},
				{nm: 'networthnonsuper', title: 'Total Non Super'},
				{nm: 'networthsuper', title: 'Total Super'},
			],

			xaooptions: {
				chart: {
					id: 'xao',
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
				},
				stroke: {
					width: 2.5,
					curve: 'straight',
					colors: ['#0078c8'],
				},
				fill: {
					shadeIntensity: 1,
					colors: ['#0078c8'],
					opacity: 0.1,
					gradient: {
						opacityFrom: 0.7,
						opacityTo: 0.1,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 0,
				},
			},
			xaoseries: [],

			nwoptions: {
				chart: {
					id: 'networth',
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
				},
				yaxis: {
					decimalsInFloat: 3,
					labels: {
						formatter: function(val) {
							var valthou = val / 1000000;
							return "$" + valthou.toLocaleString('en-us') + "m";
						},
					},
				},
				stroke: {
					width: 2.5,
					curve: 'straight',
					colors: ['#0078c8'],
				},
				fill: {
					shadeIntensity: 1,
					colors: ['#0078c8'],
					opacity: 0.1,
					gradient: {
						opacityFrom: 0.7,
						opacityTo: 0.1,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 0,
				},
			},
			nwseries: [],

			aansoptions: {
				chart: {
					id: 'assetallocns',
					type: 'donut',
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false,
					},
				},
				labels: [],
				tooltip: {
					y: {
						formatter: function(val) {
							return "$" + Math.round(val).toLocaleString('en-us');
						},
					}
				},
			},
			aansseries: [],

			aasuperoptions: {
				chart: {
					id: 'assetallocsuper',
					type: 'donut',
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false,
					},
				},
				labels: [],
				tooltip: {
					y: {
						formatter: function(val) {
							return "$" + Math.round(val).toLocaleString('en-us');
						},
					}
				},
			},
			aasuperseries: [],
		}
	},
	computed: {
		accountsNotHidden() {
			return this.$store.state.accounts.filter(item => item.hidden !== 1);
		},
		wsjcount() {
			return this.$store.state.wsjcheckcount;
		},
	},
	methods: {
		doDashboardLookups() {
			let self = this;
			let form = {
				getter: 'dashnetworth'
			};
			axios.post(
				"/post/get-dashboard.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.dashboarddata) {
					self.dashboarddata = ret.dashboarddata;
					self.xaooptions.xaxis.categories = ret.xaochart.xaxis_categories;
					self.xaoseries = ret.xaochart.series;
					self.nwoptions.xaxis.categories = ret.nwchart.xaxis_categories;
					self.nwseries = ret.nwchart.series;
					self.aansseries = ret.allocchartnonsuper.series;
					self.aansoptions.labels = ret.allocchartnonsuper.labels;
					self.aasuperseries = ret.allocchartsuper.series;
					self.aasuperoptions.labels = ret.allocchartsuper.labels;
				}
				if(ret.securities) {
					self.$store.commit('updateSecuritiesList', ret.securities);
				}
				self.lookupdone = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		wsjcount() {
			this.doDashboardLookups();
		},
	},
	mounted() {
		this.doDashboardLookups();
		document.title = "Fintrac";
	}
}
</script>

<style scoped>
.dashboard-container {
	width: calc(100% - 280px);
	left: 280px;
	position: relative;
}
.account-tile-hold {
	margin-top: 40px;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;
}
.poschg {
	color: #0A0;
}
.negchg {
	color: #C00;
}
.chartholderdash {
	width: 520px;
}
</style>